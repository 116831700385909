import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { HelmetDatoCms } from "gatsby-source-datocms";
import flattenDeep from "lodash/flattenDeep";
import React from "react";

import Layout from "../components/layout";

const Projects = ({ data }) => {
    const allProjects = data.allDatoCmsProject.edges;
    const allTags = allProjects.map(({ node }) =>
        node.tags.split(",").map((tag) => String(tag).trim())
    );
    const uniqueTags = flattenDeep(allTags).filter(
        (tag, idx, arr) => arr.indexOf(tag) === idx
    );
    const projects = data.projects;
    return (
        <Layout>
            <HelmetDatoCms seo={projects.seoMetaTags}>
                <meta
                    property="keywords"
                    content={
                        uniqueTags.length
                            ? uniqueTags.join(", ")
                            : "sntsdev, front-end programming"
                    }
                />
            </HelmetDatoCms>

            <div className="projects-list">
                <h1 className="hidden">{projects.title}</h1>
                {allProjects.map(({ node }, idx) => {
                    return (
                        <article key={idx}>
                            <div className="project">
                                <div className="project-top">
                                    <h2>
                                        <Link to={`/projects/${node.slug}`}>
                                            {node.title}
                                        </Link>
                                    </h2>
                                    <h3>
                                        <Link to={`/projects/${node.slug}`}>
                                            {node.subtitle}
                                        </Link>
                                    </h3>
                                </div>
                                <div className="cover-img">
                                    {node.coverImage && (
                                        <Link to={`/projects/${node.slug}`}>
                                            <GatsbyImage
                                                image={
                                                    node.coverImage.localImage
                                                        .childImageSharp
                                                        .gatsbyImageData
                                                }
                                                alt={node.title}
                                                key={idx}
                                            />
                                        </Link>
                                    )}
                                </div>
                                <ul className="project-tags">
                                    {node.tags
                                        .split(",")
                                        .map((tag) => String(tag).trim())
                                        .map((t, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <span>{t}</span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </article>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Projects;

export const query = graphql`
    query ProjectsQuery {
        projects: datoCmsProjectspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
        }
        allDatoCmsProject(sort: { fields: [position], order: ASC }) {
            edges {
                node {
                    slug
                    id
                    tags
                    title
                    subtitle
                    coverImage {
                        localImage {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800
                                    height: 480
                                    formats: [AUTO, WEBP, AVIF]
                                    quality: 90
                                    transformOptions: { cropFocus: NORTH }
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    body
                }
            }
        }
    }
`;
